import React, { ReactNode, ReactElement } from "react";
import { Provider } from "react-redux";

import createStore from "./src/store/createStore";

interface WrapWithProviderProps {
  element: ReactNode;
}

const wrapWithProvider = ({ element }: WrapWithProviderProps): ReactElement => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore();
  return <Provider store={store}>{element}</Provider>;
};

export default wrapWithProvider;
