import { ContractState, ContractActionTypes, ContractForms } from "../../types";
import {
  GO_NEXT_STEP,
  SET_FORM_STATE,
  SET_FORM_VALUES,
  SET_TARIFF,
  SET_CITIES,
  SET_CURRENT_SLIDE,
  SET_EDIT_MODE,
  SET_IS_PRELOADER,
} from "../actions";

const initialState: ContractState = {
  currentStep: "calculator",
  previousStep: "calculator",
  isEditMode: false,
  isPreloader: false,
  currentSlide: 0,
  passedSteps: ["calculator"],
  calculator: {
    previousStep: "calculator",
    values: {
      cityId: "",
      cityName: "",
      energyUsage: "",
      energyUsageRange: "",
      houseNumber: "",
      postalCode: "",
      street: "",
      promoCode: "",
      promoCodeDescription: "",
    },
    tariff: {
      isPending: true,
      data: null,
      error: null,
    },
    cities: {
      isPending: true,
      data: null,
      error: null,
    },
    isSubmitting: false,
    isValid: false,
  },
  personalInformation: {
    previousStep: "calculator",
    values: {
      firstName: "",
      surname: "",
      dateOfBirth: null,
      phoneMobileAreaCode: "",
      phoneMobile: "",
      emailPrivate: "",
      password: "",
      confirmPassword: "",
      zip: "",
      city: "",
      street: "",
      houseNumber: "",
      iban: "",
      firstNameAccountHolder: "",
      lastNameAccountHolder: "",
      directDebit: false,
      paymentType: "SEPA-Lastschrift",
      bankName: "",
    },
    isSubmitting: false,
    isValid: false,
  },
  reasonForContract: {
    previousStep: "personalInformation",
    values: null,
    isSubmitting: false,
    isValid: false,
  },
  billingAddress: {
    values: {
      billingFirstName: "",
      billingSurname: "",
      billingZip: "",
      billingCity: "",
      billingCityName: "",
      billingStreet: "",
      billingHouseNumber: "",
      billingEmail: "",
      billingPhoneAreaCode: "",
      billingPhone: "",
    },
    isSubmitting: false,
    isValid: false,
  },
  switchOfSupplier: {
    previousStep: "reasonForContract",
    values: {
      previousSupplier: "",
      previousProviderCodeNumber: "",
      meteringNumber: "",
      contractCancel: "stromeeCancel",
      customerSpecification: "earliest_possible_date",
      desiredDate: null,
      terminationDate: null,
      performRegistration: false,
    },
    isSubmitting: false,
    isValid: false,
  },
  relocation: {
    previousStep: "reasonForContract",
    values: {
      firstSupplier: false,
      relocationDate: null,
      meteringNumber: "",
      performRegistration: false,
    },
    isSubmitting: false,
    isValid: false,
  },
  policies: {
    previousStep: "switchOfSupplier, relocation",
    values: {
      termsAndConditions: false,
      privacyPolicy: false,
      cancellationPolicy: false,
    },
    isSubmitting: false,
    isValid: false,
  },
};

export default function (
  state = initialState,
  action: ContractActionTypes
): ContractState {
  switch (action.type) {
    case GO_NEXT_STEP:
      return {
        ...state,
        currentStep: action.payload,
        previousStep: state.currentStep,
        passedSteps:
          action.payload === "relocation" ||
          action.payload === "switchOfSupplier"
            ? state.passedSteps
                .filter(
                  (step: string) =>
                    step !== "relocation" && step !== "switchOfSupplier"
                )
                .concat([action.payload])
            : state.passedSteps.concat([action.payload]),
      };
    case SET_CURRENT_SLIDE:
      return {
        ...state,
        currentSlide: action.payload,
      };
    case SET_EDIT_MODE:
      return {
        ...state,
        isEditMode: action.payload,
        currentSlide: action.payload ? state.currentSlide : 0,
      };
    case SET_IS_PRELOADER:
      return {
        ...state,
        isPreloader: action.payload,
      };
    case SET_FORM_STATE:
      return {
        ...state,
        [action.form]: {
          ...state[action.form],
          ...action.payload,
        },
      };
    case SET_FORM_VALUES:
      return {
        ...state,
        [action.form]: {
          ...state[action.form],
          values: action.payload,
        },
      };
    case SET_TARIFF:
      return {
        ...state,
        [ContractForms.calculator]: {
          ...state[ContractForms.calculator],
          tariff: action.payload,
        },
      };
    case SET_CITIES:
      return {
        ...state,
        [ContractForms.calculator]: {
          ...state[ContractForms.calculator],
          cities: action.payload,
        },
      };
    default:
      return state;
  }
}
