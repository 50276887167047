import { combineReducers } from "redux";

import contractReducer from "./contractReducer";
import faqReducer from "./faqReducer";

const rootReducer = combineReducers({
  contract: contractReducer,
  faq: faqReducer,
});

export default rootReducer;
