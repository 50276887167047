import { FaqActionTypes, FaqState } from "../../types";
import { SET_FAQ_STATE } from "../actions";

const initialState: FaqState = {
  isPending: false,
  data: null,
  error: null,
};

export default function (
  state = initialState,
  action: FaqActionTypes
): FaqState {
  switch (action.type) {
    case SET_FAQ_STATE:
      return action.payload;
    default:
      return state;
  }
}
