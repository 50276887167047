// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-blog-article-tsx": () => import("./../src/components/Blog/Article.tsx" /* webpackChunkName: "component---src-components-blog-article-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-js": () => import("./../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-blog-article-tsx": () => import("./../src/pages/blog-article.tsx" /* webpackChunkName: "component---src-pages-blog-article-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-calculator-tsx": () => import("./../src/pages/calculator.tsx" /* webpackChunkName: "component---src-pages-calculator-tsx" */),
  "component---src-pages-cancellation-tsx": () => import("./../src/pages/cancellation.tsx" /* webpackChunkName: "component---src-pages-cancellation-tsx" */),
  "component---src-pages-change-email-tsx": () => import("./../src/pages/change-email.tsx" /* webpackChunkName: "component---src-pages-change-email-tsx" */),
  "component---src-pages-check-email-tsx": () => import("./../src/pages/check-email.tsx" /* webpackChunkName: "component---src-pages-check-email-tsx" */),
  "component---src-pages-contract-tsx": () => import("./../src/pages/contract.tsx" /* webpackChunkName: "component---src-pages-contract-tsx" */),
  "component---src-pages-email-verification-tsx": () => import("./../src/pages/email-verification.tsx" /* webpackChunkName: "component---src-pages-email-verification-tsx" */),
  "component---src-pages-faq-category-tsx": () => import("./../src/pages/faq-category.tsx" /* webpackChunkName: "component---src-pages-faq-category-tsx" */),
  "component---src-pages-faq-search-tsx": () => import("./../src/pages/faq-search.tsx" /* webpackChunkName: "component---src-pages-faq-search-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-iframe-calculator-landingpage-tsx": () => import("./../src/pages/iframe-calculator-landingpage.tsx" /* webpackChunkName: "component---src-pages-iframe-calculator-landingpage-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-password-recovery-expired-tsx": () => import("./../src/pages/password-recovery/expired.tsx" /* webpackChunkName: "component---src-pages-password-recovery-expired-tsx" */),
  "component---src-pages-password-recovery-index-tsx": () => import("./../src/pages/password-recovery/index.tsx" /* webpackChunkName: "component---src-pages-password-recovery-index-tsx" */),
  "component---src-pages-password-recovery-success-tsx": () => import("./../src/pages/password-recovery/success.tsx" /* webpackChunkName: "component---src-pages-password-recovery-success-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-conditions-tsx": () => import("./../src/pages/terms-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-tsx" */)
}

