import { CategoryFAQ, RequestState, SetFAQ } from "../../types";

export const SET_FAQ_STATE = "SET_FAQ_STATE";

export function setFaqState(payload: RequestState<Array<CategoryFAQ>>): SetFAQ {
  return {
    type: SET_FAQ_STATE,
    payload,
  };
}
