import React, {
  ReactElement,
  InputHTMLAttributes,
  Ref,
  Dispatch,
  SetStateAction,
} from "react";

import { ResponseError } from "./services/request";
import {
  GO_NEXT_STEP,
  SET_FORM_STATE,
  SET_FORM_VALUES,
  SET_TARIFF,
  SET_CITIES,
  SET_CURRENT_SLIDE,
  SET_EDIT_MODE,
  SET_IS_PRELOADER,
  SET_FAQ_STATE,
} from "./store/actions";

export type SetState<T> = Dispatch<SetStateAction<T>>;

export interface InputBaseProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  type?: string;
  error?: string | ReactElement;
  inputClassName?: string;
  inputRef?: Ref<HTMLInputElement>;
  full?: boolean;
}

export type EnergyCalculatorFormValues = {
  postalCode?: any;
  cityId?: string;
  cityName?: string;
  street?: string;
  houseNumber?: string;
  energyUsage?: string;
  energyUsageRange?: string;
  promoCode?: string;
  promoCodeDescription?: string;
};

// API types

export type APIResponse<T> = Promise<T | { err: ResponseError }>;

export type RequestState<D> = {
  isPending: boolean;
  data: D | null;
  error: ResponseError | null;
};

export interface ApiException {
  statusCode?: number;
  message?: string;
  status?: string;
  error?: string;
  errors?: Record<string, unknown>;
  timestamp?: string;
  path?: string;
}

export type TariffParameters = {
  usage: string;
  cityId: string;
  streetName?: string;
  houseNumber?: string;
  promoCode?: string;
};

export interface Tariff {
  estimatedPerMonth?: number;
  annual?: number;
  stromee?: number;
  networkCosts?: number;
  consumptionPrice?: number;
  consumptionPricePerKWh?: number;
  minimumTerm?: string;
  greenElectricity?: string;
  stromeeApp?: string;
  promoCode?: string;
  promoCodeDescription?: string;
}

export type CityParameters = {
  postalCode: any;
};

export interface City {
  id: string;
  postcode: number | string;
  name: string;
}

export type StreetParameters = {
  postcode: number;
};

export interface Street {
  id: number;
  name: string;
}

export type AddressValidationParameters = {
  cityId: number;
  streetName: string;
  houseNumber: string;
};

export interface AddressValidation {
  status: boolean;
}

export type EmailVerificationParams = {
  token: string;
};

export type EmailVerificationType = {
  refreshToken: "string";
  accessToken: "string";
  customer: Record<string, unknown>;
};

// Redux stare types

export interface BaseFormState {
  isSubmitting: boolean;
  isValid: boolean;
}

export interface PersonalDataFormValues {
  firstName: string;
  surname: string;
  dateOfBirth: string | null;
  phoneMobileAreaCode: string;
  phoneMobile: string;
  emailPrivate: string;
  password: string;
  confirmPassword: string;
  zip: string;
  city: string;
  street: string;
  houseNumber: string;
  iban: string;
  firstNameAccountHolder: string;
  lastNameAccountHolder: string;
  directDebit: boolean;
  paymentType: string;
  bankName: string;
}

export interface BillingAddressFormValues {
  billingFirstName: string;
  billingSurname: string;
  billingZip: any;
  billingCity: string;
  billingCityName: string;
  billingStreet: string;
  billingHouseNumber: string;
  billingEmail: string;
  billingPhoneAreaCode: string;
  billingPhone: string;
}

export interface SwitchOfSupplierFormValues {
  previousSupplier?: string;
  previousProviderCodeNumber?: string;
  meteringNumber?: string;
  contractCancel: string;
  customerSpecification: string;
  desiredDate?: string | null;
  performRegistration: boolean;
  terminationDate: string | null;
}

export interface RelocationFormValues {
  firstSupplier?: boolean;
  relocationDate?: string | null;
  meteringNumber?: string;
  performRegistration: boolean;
}

export interface PoliciesFormValues {
  termsAndConditions: boolean;
  privacyPolicy: boolean;
  cancellationPolicy: boolean;
}

export interface CalculatorState extends BaseFormState {
  values: EnergyCalculatorFormValues;
  tariff: RequestState<Tariff>;
  cities: RequestState<Array<City>>;
  previousStep: ContractStepName;
}

export interface PersonalInformationState extends BaseFormState {
  values: PersonalDataFormValues;
  previousStep: ContractStepName;
}

export interface SwitchOfSupplierState extends BaseFormState {
  values: SwitchOfSupplierFormValues;
  previousStep: ContractStepName;
}

export interface RelocationState extends BaseFormState {
  values: RelocationFormValues;
  previousStep: ContractStepName;
}

export interface PoliciesState extends BaseFormState {
  values: PoliciesFormValues;
  previousStep: ContractStepName;
}

export interface BillingAddressState extends BaseFormState {
  values: BillingAddressFormValues;
}

export interface ReasonForContractState extends BaseFormState {
  values: null;
  previousStep: ContractStepName;
}

export type ContractStepName =
  | "calculator"
  | "personalInformation"
  | "reasonForContract"
  | "switchOfSupplier"
  | "relocation"
  | "policies"
  | "switchOfSupplier, relocation";

export type ContractStep =
  | "calculator"
  | "personalInformation"
  | "reasonForContract"
  | "switchOfSupplier"
  | "relocation"
  | "policies";

export enum ContractForms {
  calculator = "calculator",
  personalInformation = "personalInformation",
  switchOfSupplier = "switchOfSupplier",
  relocation = "relocation",
  policies = "policies",
  billingAddress = "billingAddress",
  reasonForContract = "reasonForContract",
}

export enum ContractSteps {
  calculator = "calculator",
  personalInformation = "personalInformation",
  reasonForContract = "reasonForContract",
  switchOfSupplier = "switchOfSupplier",
  relocation = "relocation",
  policies = "policies",
}

export interface ContractState {
  currentStep: ContractStep;
  previousStep: ContractStep;
  currentSlide: number;
  isEditMode: boolean;
  isPreloader: boolean;
  passedSteps: Array<ContractStep>;
  [ContractForms.calculator]: CalculatorState;
  [ContractForms.personalInformation]: PersonalInformationState;
  [ContractForms.switchOfSupplier]: SwitchOfSupplierState;
  [ContractForms.relocation]: RelocationState;
  [ContractForms.policies]: PoliciesState;
  [ContractForms.billingAddress]: BillingAddressState;
  [ContractForms.reasonForContract]: ReasonForContractState;
}

export interface AppState {
  contract: ContractState;
  faq: FaqState;
}

export type ContractFormValues =
  | EnergyCalculatorFormValues
  | PersonalDataFormValues
  | SwitchOfSupplierFormValues
  | RelocationFormValues
  | PoliciesFormValues
  | BillingAddressFormValues;

export interface GoNextStep {
  type: typeof GO_NEXT_STEP;
  payload: ContractStep;
}

export interface SetCurrentSlide {
  type: typeof SET_CURRENT_SLIDE;
  payload: number;
}

export interface SetEditMode {
  type: typeof SET_EDIT_MODE;
  payload: boolean;
}

export interface SetIsPreloader {
  type: typeof SET_IS_PRELOADER;
  payload: boolean;
}

export interface SetFormState {
  type: typeof SET_FORM_STATE;
  form: ContractForms;
  payload: BaseFormState;
}

export interface SetFormValues {
  type: typeof SET_FORM_VALUES;
  form: ContractForms;
  payload: ContractFormValues;
}

export interface SetTariff {
  type: typeof SET_TARIFF;
  payload: RequestState<Tariff>;
}

export interface SetCities {
  type: typeof SET_CITIES;
  payload: RequestState<Array<City>>;
}

export type ContractActionTypes =
  | GoNextStep
  | SetFormState
  | SetFormValues
  | SetTariff
  | SetCities
  | SetCurrentSlide
  | SetEditMode
  | SetIsPreloader;

export type FaqActionTypes = SetFAQ;

export interface PrivacyPolicy {
  success: boolean;
  content: string;
}
export interface CreateContract {
  success: boolean;
  content: string;
}
export type SVGReactComponent = React.SFC<React.SVGProps<SVGSVGElement>>;

export interface StepParameters {
  stepName: string;
  stepTitle: string;
  isDisabled: boolean;
  background?: SVGReactComponent;
  isCurrent: boolean;
}

export interface EnergyTariffValues {
  tariff: Tariff;
  formValues: EnergyCalculatorFormValues;
}

export type ButtonVariant = "blue" | "orange" | "white" | "grayBorder";

export interface Iban {
  bankName: string;
}

export interface PromoCode {
  code: string;
  description: string;
}

export type ProviderParameters = {
  cityId: number;
  usage: number;
};

export interface Provider {
  id: string;
  codeNumber: string;
  name: string;
  primary: boolean;
}

export interface Contract {
  emailPrivate: string;
  password: string;
  firstName: string;
  surname: string;
  dateOfBirth: string | null;
  phoneMobileAreaCode: string;
  phoneMobile: string;
  zip: number;
  city: string;
  street: string;
  houseNumber: string;
  usage: number;
  billingAlternativeAddress?: boolean;
  billingFirstName?: string;
  billingSurname?: string;
  billingZip?: number;
  billingCity?: string;
  billingStreet?: string;
  billingHouseNumber?: string;
  billingEmail?: string;
  billingPhoneAreaCode?: string;
  billingPhone?: string;
  iban?: string;
  alternativeAccountHolder?: string;
  customerSpecification?: string;
  previousProviderCodeNumber?: string;
  meteringNumber?: string;
  desiredDate?: string | null;
  relocationDate?: string | null;
  terminationDate?: string | null;
}

export interface Article {
  agent_id: number;
  category_id: number;
  description_text: string;
  description: string;
  folder_id: number;
  id: number;
  title: string;
  status: number;
}

export interface CategoryFAQ {
  created_at?: string;
  description?: string;
  id: number;
  name?: string;
  updated_at?: string;
  visibility?: number;
  answers?: Array<Article>;
}

export interface SearchResult {
  answers: Array<Article>;
}

export type FaqState = RequestState<Array<CategoryFAQ>>;

export interface SetFAQ {
  type: typeof SET_FAQ_STATE;
  payload: RequestState<Array<CategoryFAQ>>;
}

export interface CustomAnalyticEvent {
  event: string;
  stepName: string;
  action: string;
  label: string;
}

export interface Tip {
  title: string;
  description: string;
  categoryName: string;
}

export interface TipsCategory {
  title: string;
  icon: SVGReactComponent;
  categoryName: string;
  authorInfo: string;
}

export enum LegalNames {
  Agb = "agb",
  Impressum = "impressum",
  Widerruf = "widerruf",
  Datenschutz = "datenschutz",
}

export interface PasswordRecoveryFormValues {
  password: string;
  passwordRepeat: string;
}

export interface ConfirmRestorePasswordRequest {
  token: string;
  password: string;
}

export interface UrlParams {
  [key: string]: string;
}
