import {
  GoNextStep,
  SetCurrentSlide,
  ContractForms,
  BaseFormState,
  SetFormState,
  ContractStep,
  SetFormValues,
  ContractFormValues,
  SetTariff,
  SetCities,
  RequestState,
  Tariff,
  City,
  SetEditMode,
  SetIsPreloader,
} from "../../types";

export const GO_NEXT_STEP = "GO_NEXT_STEP";
export const SET_FORM_STATE = "SET_FORM_STATE";
export const SET_FORM_VALUES = "SET_FORM_VALUES";
export const SET_TARIFF = "SET_TARIFF";
export const SET_CITIES = "SET_CITIES";
export const SET_CURRENT_SLIDE = "SET_CURRENT_SLIDE";
export const SET_EDIT_MODE = "SET_EDIT_MODE";
export const SET_IS_PRELOADER = "SET_IS_PRELOADER";

export function goNextStep(payload: ContractStep): GoNextStep {
  return {
    type: GO_NEXT_STEP,
    payload,
  };
}

export function setCurrentSlide(payload: number): SetCurrentSlide {
  return {
    type: SET_CURRENT_SLIDE,
    payload,
  };
}

export function setEditMode(payload: boolean): SetEditMode {
  return {
    type: SET_EDIT_MODE,
    payload,
  };
}

export function setIsPreloader(payload: boolean): SetIsPreloader {
  return {
    type: SET_IS_PRELOADER,
    payload,
  };
}

export function setFormState(
  form: ContractForms,
  payload: BaseFormState
): SetFormState {
  return {
    type: SET_FORM_STATE,
    form,
    payload,
  };
}

export function setFormValues(
  form: ContractForms,
  payload: ContractFormValues
): SetFormValues {
  return {
    type: SET_FORM_VALUES,
    form,
    payload,
  };
}

export function setTariffState(payload: RequestState<Tariff>): SetTariff {
  return {
    type: SET_TARIFF,
    payload,
  };
}

export function setCitiesState(payload: RequestState<City[]>): SetCities {
  return {
    type: SET_CITIES,
    payload,
  };
}
